module.exports = new Promise(async (resolve, reject) => {
                try {
                    // 1. Fetch the config.json
                    const response = await fetch('/config.json');
                    const config = await response.json();
        
                    // 2. Save all envs to the window react-qiwa-core config
                    console.log('config from /config.json in micro:', config);
                    window.reactQiwaConfig = config;
        
                    // 3. Extract the microfrontend URL based on environment
                    const MICROFRONTEND_URL = config.CORE_MICROFRONTEND_URL;
        
                    if(!MICROFRONTEND_URL) {
                      throw new Error('CORE_MICROFRONTEND_URL is not defined in fetched config', { cause: 'Most likely the CORE_MICROFRONTEND_URL env is missing in your config' + JSON.stringify(config, null, 2) });
                    }
        
                    // 4. Load the remote's remoteEntry.js
                    const script = document.createElement('script');
                    script.src = MICROFRONTEND_URL;
                    script.onload = () => {
                        // Create a proxy for the loaded remote
                        const proxy = {
                            get: (request) => window['qiwa_remote_visuals'].get(request),
                            init: (arg) => {
                                try {
                                    return window['qiwa_remote_visuals'].init(arg);
                                } catch(e) {
                                    console.warn('remote container already initialized');
                                }
                            }
                        };
                        resolve(proxy);
                    };
                    script.onerror = (error) => {
                        const waitForGlobal = function(key, callback) {
                          if (window[key]) {
                            callback();
                          } else {
                            setTimeout(function() {
                              waitForGlobal(key, callback);
                            }, 100);
                          }
                        };

                        waitForGlobal("__qiwaCorePubSub", function() {                          
                          window.__qiwaCorePubSub.notify({
                            type: 'qiwa-core/MFRemoteEntryError',
                            payload: { error },
                          });
                        });

                        console.error('Failed to load the remoteEntry.js:', error);
                        reject(error);
                    };
        
                    // Add the script tag to load the remote
                    document.head.appendChild(script);
                } catch (error) {
                    console.error('Error while setting up the remote:', error);
                    reject(error);
                }
            });